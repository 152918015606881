@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto+Slab:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  html {
    @apply font-poppins;
  }
}
html {
  scroll-behavior: smooth;
}
@layer components {
  .about-box {
    @apply dark:border-2 rounded-xl p-6 flex gap-4 border-[#212425];
  }
  .after-effect {
    @apply text-7xl font-bold font-robotoSlab inline-block relative after:absolute  transform after:-translate-y-[50%] after:bg-gradient-to-r from-[#FA5252] to-[#DD2476] after:h-0.5 after:rounded-md after:content-[""] md:after:w-48  after:top-[50%] dark:text-white;
  }
  .resume-btn {
    @apply bg-[#EDF2F2] cursor-default px-5 py-2 rounded-lg  text-gray-lite dark:bg-[#1C1C1C] dark:text-[#A6A6A6];
  }
  .socialbtn {
    @apply w-10 h-10 rounded-lg hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  flex justify-center items-center  hover:text-white transition-colors duration-300  ease-in-out bg-[#F3F6F6] dark:bg-[#212425];
  }
  .fillter-btn {
    @apply text-gray-lite dark:text-[#A6A6A6] hover:text-[#FA5252] transition duration-300 cursor-pointer;
  }
}
#root {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
